<!-- @format -->

<template>
	<div>
		<div id="swagger-ui"></div>
	</div>
</template>
	
<script>
import "../assets/css/swagger/swagger-ui.css";
import SwaggerUIBundle from "swagger-ui-dist/swagger-ui-bundle.js";
import "swagger-ui-dist/swagger-ui-standalone-preset.js";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
	computed: {
		...mapGetters({
			getCustomerAdminIsAuthorizeToAccessToken: "getCustomerAdminIsAuthorizeToAccessToken"
		}),
	},
	methods: {
		...mapActions({
			fechIsAuthorizeToAccessToken: "fechIsAuthorizeToAccessToken",
		}),
		storeVariable() {
			return this.getCustomerAdminIsAuthorizeToAccessToken
		}
	},
	async created() {
		await this.fechIsAuthorizeToAccessToken();
		if(!this.getCustomerAdminIsAuthorizeToAccessToken) {
			window.location.href = "/shipments";
		}
	},
	mounted() {
		const ui = SwaggerUIBundle({
			spec: require("../../swagger.json"),
			dom_id: "#swagger-ui",
			presets: [
				SwaggerUIBundle.presets.apis,
				SwaggerUIBundle.SwaggerUIStandalonePreset,
			],
			layout: "BaseLayout",
		});
		this.ui = ui;
	},
	beforeDestroy() {
		if (this.ui) {
			this.ui.destroy();
		}
	},
};
</script>